export const fontSizes = [
  '0.75rem', // 0
  '0.875rem', // 1
  '1rem', // 2
  '1.25rem', // 3
  '1.5rem', // 4
  '2rem', // 5
  '2.5rem', // 6
  '3rem', // 7
];
