import * as React from 'react';
import { SVGProps } from 'react';
import styled from '@emotion/styled';
const BaseComponent = ({
  size = '1em',
  ...props
}: SVGProps<SVGSVGElement> & {
  size?: string | number;
  tabIndex?: number;
  focusable?: boolean;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      fill="currentColor"
      role="img"
      height={size}
      width={size}
      {...props}
    >
      <path d="m9.462 9.306-.692 1.951h1.383z" />
      <path d="m12 1c-6.627 0-12 4.208-12 9.399 0 3.356 2.246 6.301 5.625 7.963-1.299 4.45-1.333 4.47-1.113 4.599.276.161.634-.005 5.357-3.311.692.097 1.404.148 2.131.148 6.627 0 12-4.208 12-9.399s-5.373-9.399-12-9.399zm-5.942 12.023c0 .362-.311.657-.692.657s-.692-.295-.692-.657v-4.086h-1.08c-.375 0-.679-.302-.679-.673s.303-.674.678-.674h3.545c.375 0 .679.302.679.673s-.305.674-.679.674h-1.08zm5.818.553c-.138.063-.288.095-.44.095-.289 0-.51-.116-.576-.304l-.343-.891h-2.111l-.343.892c-.066.187-.287.303-.576.303-.152 0-.302-.032-.44-.095-.191-.087-.374-.328-.164-.977l1.656-4.33c.117-.329.471-.668.922-.678.452.01.807.349.923.679l1.655 4.328c.211.65.028.891-.163.978zm3.499.003h-2.221c-.366 0-.663-.283-.663-.63v-4.671c0-.379.317-.688.707-.688s.707.308.707.688v4.04h1.471c.366 0 .663.283.663.63-.001.348-.298.631-.664.631zm5.419-.518c-.025.181-.122.344-.269.454-.12.09-.266.139-.416.139-.218.001-.423-.1-.553-.273l-1.624-2.137-.24.239v1.5c0 .38-.31.688-.693.688-.382 0-.692-.308-.692-.688v-4.705c0-.379.31-.688.692-.688s.692.308.692.688v1.478l1.932-1.919c.099-.099.236-.153.384-.153.173 0 .346.074.476.203.121.12.194.275.204.436.01.162-.044.311-.153.419l-1.578 1.567 1.704 2.243c.113.145.161.329.134.509z" />
    </svg>
  );
};
const SvgKakaotalk = styled(BaseComponent)`
  outline: none;
`;
SvgKakaotalk.defaultProps = {
  size: 24,
  tabIndex: -1,
  focusable: false,
};
export default SvgKakaotalk;
